module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Voyeur House | Hotscope - LIVE & FREE Hidden Cams 24/7","short_name":"Voyeur House | Hotscope - LIVE & FREE Hidden Cams 24/7","start_url":"https://ryanfitzgerald.github.io/devblog/","background_color":"#fff","theme_color":"#cf5c5c","display":"minimal-ui","icon":"src/main.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"43aca72df9231b1e9b5cda61aad638cb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
